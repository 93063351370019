import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { GridToolbarContainer } from "@mui/x-data-grid/components/containers/GridToolbarContainer";
import type { GridRowModesModel } from "@mui/x-data-grid/models/api/gridEditingApi";
import { GridRowModes } from "@mui/x-data-grid/models/gridEditRowModel";
import type { GridRowsProp } from "@mui/x-data-grid/models/gridRows";
import type { EProductType } from "api/products";
import type { FC } from "react";
import { useParams } from "react-router-dom";
import { generateRandomID } from "utils";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import styled from "@emotion/styled";

interface ProductEditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  type: EProductType;
}

const TableToolsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 100%;
`;

const ProductEditToolbar: FC<ProductEditToolbarProps> = ({ setRows, setRowModesModel, type }) => {
  const { OEM } = useParams();
  const handleClick = () => {
    const id = generateRandomID();
    setRows((oldRows) => [
      {
        id,
        country: "",
        type,
        contractId: "",
        subscriptionTypeId: "",
        name: "",
        hideVolume: OEM === "telekom",
        isNew: true,
      },
      ...oldRows,
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <TableToolsContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add Product
        </Button>
        <GridToolbarQuickFilter />
      </TableToolsContainer>
    </GridToolbarContainer>
  );
};

export default ProductEditToolbar;
