import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import type { SxProps } from "@mui/material/styles";
import { GridEditInputCell } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { GridRowModes } from "@mui/x-data-grid/models/gridEditRowModel";
import type { GridRowsProp } from "@mui/x-data-grid/models/gridRows";
import { EProductType, type IProductApi } from "api/products";
import ConfirmDialog from "components/atoms/confirm-dialog";
import ProductEditToolbar from "components/molecules/product-edit-toolbar";
import StyledDataGrid from "components/molecules/styled-data-grid";
import { country_currencies, country_options } from "components/shared/constants";
import type { FC } from "react";
import useProductEditor from "./useProductEditor";

interface IProductsEditorProps {
  productsData: GridRowsProp;
  handleEditSave: (product: IProductApi) => Promise<string>;
  handleProductDelete: (SK: string) => void;
  loading: boolean;
  type: EProductType;
  OEM?: string;
  wrapperCss?: SxProps;
}

const ProductsEditor: FC<IProductsEditorProps> = ({
  wrapperCss,
  productsData,
  handleEditSave,
  handleProductDelete,
  OEM,
  loading,
  type,
}) => {
  const {
    rows,
    rowModesModel,
    isConfirmEditOpened,
    isConfirmDeleteOpened,
    confirmEditDialogText,
    confirmDeleteDialogText,
    editSubscriptionTypeId,
    sortModel,
    setRows,
    setRowModesModel,
    setSortModel,
    handleEditClick,
    handleCardEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    handleConfirmClose,
    handleConfirmEdit,
    handleRowModesModelChange,
    handleConfirmDelete,
    handleRowEditStop,
  } = useProductEditor({ productsData, handleProductDelete, handleEditSave });
  return (
    <Box sx={wrapperCss}>
      <ConfirmDialog
        title="Confirm edit action"
        text={confirmEditDialogText}
        isOpen={isConfirmEditOpened}
        handleClose={handleConfirmClose}
        handleConfirm={handleConfirmEdit}
        value={editSubscriptionTypeId}
      />
      <ConfirmDialog
        title="Confirm delete action"
        text={confirmDeleteDialogText}
        isOpen={isConfirmDeleteOpened}
        handleClose={handleConfirmClose}
        handleConfirm={handleConfirmDelete}
        value={editSubscriptionTypeId}
      />
      <Box key="product-editor">
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr", height: "80vh" }}>
          <StyledDataGrid
            rowHeight={100}
            columnVisibilityModel={{
              ...(OEM === "telekom" && {
                currency: false,
                price: false,
                volumeValue: false,
                volumeUnit: false,
                isSlave: false,
                isUnlimited: false,
                masterId: false,
              }),
              ...(OEM === "bmw" && {
                isSlave: false,
                isUnlimited: false,
                masterId: false,
              }),
              ...(OEM === "bmwna" && {
                isSlave: false,
                masterId: false,
              }),
              ...(type === EProductType.pass && {
                hideVolume: false,
                contractId: false,
              }),
              type: false,
              currency: false,
            }}
            columns={[
              {
                headerName: "Name",
                field: "name",
                editable: true,
                flex: 0.7,
              },
              {
                headerName: "Country",
                field: "country",
                type: "singleSelect",
                valueOptions: country_options.filter(({ id }) => id !== "common").map(({ id }) => id),
                editable: true,
                flex: 0.3,
              },
              {
                headerName: "Contract ID",
                field: "contractId",
                editable: true,
                flex: 0.3,
              },
              {
                headerName: "Subscription Type ID",
                field: "subscriptionTypeId",
                type: "number",
                editable: true,
                flex: 0.3,
              },
              {
                headerName: "Type",
                field: "type",
                type: "singleSelect",
                editable: true,
                flex: 0.4,
                valueOptions: () => {
                  switch (type) {
                    case EProductType.pass:
                      return [EProductType.pass];
                    case EProductType.subscription:
                      return [EProductType.subscription];
                    default:
                      return Object.values(EProductType);
                  }
                },
              },
              {
                headerName: "Price",
                field: "price",
                editable: true,
                type: "number",
                align: "right",
                headerAlign: "right",
                width: 80,
                valueFormatter: (value, { country: rowCountry }) => {
                  const currency = country_currencies.find(({ country }) => country === rowCountry);
                  const symbol = currency ? currency.symbol : "";
                  return `${value} ${symbol}`;
                },
                renderEditCell: (params) => (
                  <GridEditInputCell
                    {...params}
                    inputProps={{
                      min: 0,
                    }}
                  />
                ),
              },
              {
                headerName: "Currency",
                field: "currency",
                editable: false,
              },
              {
                headerName: "Data volume",
                field: "volumeValue",
                editable: true,
                type: "number",
                width: 110,
                align: "right",
                headerAlign: "right",
                renderEditCell: (params) => (
                  <GridEditInputCell
                    {...params}
                    inputProps={{
                      min: 0,
                    }}
                  />
                ),
              },
              {
                headerName: "Data volume unit",
                field: "volumeUnit",
                editable: true,
                width: 130,
                description: "Eg. MB, GB",
              },
              {
                headerName: "Hide Volume",
                field: "hideVolume",
                editable: true,
                type: "boolean",
                flex: 0.35,
              },
              {
                headerName: "Slave product",
                field: "isSlave",
                editable: true,
                type: "boolean",
                flex: 0.35,
              },
              {
                headerName: "Master ID",
                field: "masterId",
                editable: true,
                width: 80,
                description: "Id of master produc",
              },
              {
                headerName: "Unlimited",
                field: "isUnlimited",
                editable: true,
                type: "boolean",
                flex: 0.35,
              },
              {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                width: 80,
                cellClassName: "actions",
                getActions: ({ id, row }) => {
                  const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                  if (isInEditMode) {
                    return [
                      <GridActionsCellItem
                        key="save"
                        icon={<SaveIcon />}
                        label="Save"
                        sx={{
                          color: "primary.main",
                        }}
                        onClick={handleSaveClick(id, row)}
                      />,
                      <GridActionsCellItem
                        key="cancel"
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(id)}
                        color="inherit"
                      />,
                    ];
                  }

                  const actions = [
                    <GridActionsCellItem
                      key="edit"
                      icon={<EditIcon />}
                      label="Edit"
                      className="textPrimary"
                      onClick={handleEditClick(id)}
                      color="inherit"
                      showInMenu
                    />,
                    <GridActionsCellItem
                      key="delete"
                      icon={<DeleteIcon />}
                      label="Delete"
                      onClick={handleDeleteClick(id)}
                      color="inherit"
                      showInMenu
                    />,
                  ];

                  if (OEM !== "telekom" && row?.SK) {
                    actions.push(
                      <GridActionsCellItem
                        icon={<EditRoadIcon />}
                        label="Card Editor"
                        className="textPrimary"
                        onClick={handleCardEditClick(row.SK, row.country)}
                        color="inherit"
                      />
                    );
                  }
                  return actions;
                },
              },
            ]}
            rows={rows}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            slots={{
              // @ts-ignore
              toolbar: ProductEditToolbar,
            }}
            slotProps={{ toolbar: { setRows, setRowModesModel, type } }}
            loading={loading}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            initialState={{
              sorting: {
                sortModel: [{ field: "country", sort: "asc" }],
              },
              columns: {
                columnVisibilityModel: {
                  currency: false,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductsEditor;
