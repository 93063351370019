import type { GridSortModel } from "@mui/x-data-grid";
import type { GridRowModesModel } from "@mui/x-data-grid/models/api/gridEditingApi";
import type { GridEventListener } from "@mui/x-data-grid/models/events/gridEventListener";
import { GridRowModes } from "@mui/x-data-grid/models/gridEditRowModel";
import type { GridRowId, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridRowEditStopReasons } from "@mui/x-data-grid/models/params/gridRowParams";
import type { IProductApi } from "api/products";
import { country_currencies } from "components/shared/constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IUseProductsEditorProps {
  productsData: GridRowsProp;
  handleEditSave: (product: IProductApi) => Promise<string>;
  handleProductDelete: (SK: string) => void;
}

const useProductEditor = ({ productsData, handleProductDelete, handleEditSave }: IUseProductsEditorProps) => {
  const [rows, setRows] = useState<readonly GridValidRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [isConfirmEditOpened, setIsConfirmEditOpened] = useState<boolean>(false);
  const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState<boolean>(false);
  const [editSubscriptionTypeId, setEditSubscriptionTypeId] = useState<string>("");
  const [confirmEditDialogText, setConfirmEditDialogText] = useState<string>("");
  const [confirmDeleteDialogText, setConfirmDeleteDialogText] = useState<string>("");
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "country", sort: "asc" }]);
  const navigate = useNavigate();

  useEffect(() => {
    setRows(productsData);
  }, [productsData]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleCardEditClick = (id: GridRowId, country: string) => () => {
    const productCardEditorData = JSON.parse(sessionStorage.getItem("ProductCardEditorData") ?? "{}");
    productCardEditorData.country = country;
    sessionStorage.setItem("ProductCardEditorData", JSON.stringify(productCardEditorData));

    navigate(id.toString());
  };

  const handleSaveClick = (id: GridRowId, row: GridValidRowModel) => () => {
    const { isNew, name } = row;
    if (isNew) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      return;
    }
    setEditSubscriptionTypeId(id.toString());
    setConfirmEditDialogText(`Do you really want to change the values of product "${name}"?`);
    setIsConfirmEditOpened(true);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const row = rows.find(({ id: findId }) => id === findId);
    if (row) {
      const { name } = row;
      setEditSubscriptionTypeId(id.toString());
      setConfirmDeleteDialogText(`Do you really want to delete the product "${name}"?`);
      setIsConfirmDeleteOpened(true);
    }
  };

  const handleConfirmDelete = (id: string) => {
    const row = rows.find(({ id: findId }) => id === findId);
    if (row) {
      const { SK } = row;
      if (SK) handleProductDelete(SK);
    }
    setRows(rows.filter((row: GridValidRowModel) => row.id !== id));
    setIsConfirmDeleteOpened(false);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: GridValidRowModel) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row: GridValidRowModel) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridValidRowModel) => {
    return saveRow(newRow);
  };

  const saveRow = async (newRow: GridValidRowModel) => {
    const updatedProduct: IProductApi = { ...newRow } as IProductApi;
    const currency_data = country_currencies.find((currency) => currency.country === updatedProduct?.country);
    const currency = currency_data?.isoCode ?? "";
    const setSK = await handleEditSave({ ...updatedProduct, currency } as IProductApi);
    const updatedRow = { ...updatedProduct, SK: setSK, isNew: false };
    setRows(rows.map((row: GridValidRowModel) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleConfirmClose = () => {
    setIsConfirmEditOpened(false);
    setIsConfirmDeleteOpened(false);
  };

  const handleConfirmEdit = (id: string) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setIsConfirmEditOpened(false);
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return {
    rows,
    rowModesModel,
    isConfirmEditOpened,
    isConfirmDeleteOpened,
    confirmEditDialogText,
    confirmDeleteDialogText,
    editSubscriptionTypeId,
    sortModel,
    setRows,
    setRowModesModel,
    setSortModel,
    handleEditClick,
    handleCardEditClick,
    handleSaveClick,
    handleDeleteClick,
    handleCancelClick,
    processRowUpdate,
    handleConfirmClose,
    handleConfirmEdit,
    handleRowModesModelChange,
    handleConfirmDelete,
    handleRowEditStop,
  };
};

export default useProductEditor;
