import { ECurrencyCode, ECurrencySymbol, ERoutes } from "./enums";
import { ICountryMapping, ICurrency, IDropdownOption, IGCCPage, INamespaceOption } from "./interfaces";

export const content_types: Array<string> = ["html", "pdf", "intl"];

// map between app and AAX environments to avoid double env. selection
export const getEnvironmentType = (oem?: string, env?: string) => {
  if (oem === "bmwna" && env === "review") return "int";
  if (oem === "daimlerna" && env === "review") return "int";
  if (env === "prod") return "prod";

  return "tua";
};

export const getApiBaseUrl = (api_url: string) => {
  return `${api_url}/api/admin`;
};
export const getContentUrl = (base_url: string, key: string) => {
  return `${base_url}/${key}`;
};

export const getDropdownOption = (value: string): IDropdownOption => {
  return { id: value, key: value, label: value };
};

export const country_options: IDropdownOption[] = [
  { id: "at", label: "Austria", key: "austria" },
  { id: "be", label: "Belgium", key: "belgium" },
  { id: "cz", label: "Czech Republic", key: "czech-republic" },
  { id: "dk", label: "Denmark", key: "denmark" },
  { id: "fi", label: "Finland", key: "finland" },
  { id: "fr", label: "France", key: "france" },
  { id: "gr", label: "Greece", key: "greece" },
  { id: "hu", label: "Hungary", key: "hungary" },
  { id: "is", label: "Iceland", key: "iceland" },
  { id: "de", label: "Germany", key: "germany" },
  { id: "ie", label: "Ireland", key: "ireland" },
  { id: "it", label: "Italy", key: "italy" },
  { id: "lu", label: "Luxembourg", key: "luxembourg" },
  { id: "nl", label: "Netherlands", key: "netherlands" },
  { id: "no", label: "Norway", key: "norway" },
  { id: "pl", label: "Poland", key: "poland" },
  { id: "pt", label: "Portugal", key: "portugal" },
  { id: "ro", label: "Romania", key: "romania" },
  { id: "sk", label: "Slovakia", key: "slovakia" },
  { id: "es", label: "Spain", key: "spain" },
  { id: "se", label: "Sweden", key: "sweden" },
  { id: "ch", label: "Switzerland", key: "switzerland" },
  { id: "gb", label: "United Kingdom", key: "united-kingdom" },
  { id: "us", label: "Unites States of America", key: "usa" },
  { id: "ca", label: "Canada", key: "canada" },
  { id: "common", label: "Common", key: "common" },
];

export const environment_options: IDropdownOption[] = [
  { id: "int", label: "INT" },
  { id: "tua", label: "TuA" },
  { id: "prod", label: "Prod" },
];

// export const region_options: IDropdownOption[] = [
//   { id: "emea", label: "emea" },
//   { id: "na", label: "na" },
// ];

export const oem_options: IDropdownOption[] = [
  { id: "bmw", label: "BMW" },
  { id: "bmwna", label: "BMWNA" },
  { id: "telekom", label: "VOLVO/POLESTAR" },
  { id: "rrmc", label: "RRMC" },
  { id: "daimlerna", label: "DAIMLER" },
];

export const cidr_country_options: IDropdownOption[] = [
  { id: "de", label: "DE" },
  { id: "nl", label: "NL" },
  { id: "at", label: "AT" },
  { id: "gb", label: "GB" },
  { id: "fr", label: "FR" },
  { id: "it", label: "IT" },
  { id: "es", label: "ES" },
  { id: "se", label: "SE" },
  { id: "be", label: "BE" },
  { id: "ch", label: "CH" },
  { id: "no", label: "NO" },
  { id: "lu", label: "LU" },
  { id: "cz", label: "CZ" },
  { id: "ie", label: "IE" },
  { id: "pl", label: "PL" },
  { id: "pt", label: "PT" },
  { id: "fi", label: "FI" },
  { id: "dk", label: "DK" },
  { id: "ro", label: "RO" },
  { id: "hu", label: "HU" },
  { id: "is", label: "IS" },
  { id: "sk", label: "SK" },
  { id: "gr", label: "GR" },
  { id: "us", label: "US" },
  { id: "ca", label: "CA" },
];

export const all_locales: Array<string> = [
  "cs",
  "de",
  "da",
  "en",
  "el",
  "es",
  "fi",
  "fr",
  "hu",
  "it",
  "is",
  "nb",
  "nl",
  "pl",
  "pt",
  "ro",
  "sk",
  "sv",
];

export const pages: Array<string> = [
  "common",
  // "faq",
  // "home",
  // "infos",
  // "login",
  // "password_reset",
  // "register_form",
  // "register_method",
];

export const no_country_pages: Array<string> = ["common"];

export const oem_pages: Array<string> = [
  "common",
  // "country_selection",
  // "faq",
  // "home",
  // "infos",
  // "login",
  // "password_reset",
  // "register_form",
  // "register_method",
];

export const country_mapping: ICountryMapping[] = [
  {
    id: "at",
    locales: ["en", "de"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Austria",
  },
  {
    id: "be",
    locales: ["en", "de", "fr", "nl"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Belgium",
  },
  {
    id: "cz",
    locales: ["en", "cs"],
    oem_list: ["bmw", "telekom"],
    label: "Czech Republic",
  },
  {
    id: "dk",
    locales: ["en", "da"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Denmark",
  },
  {
    id: "fi",
    locales: ["en", "fi", "sv"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Finland",
  },
  {
    id: "fr",
    locales: ["en", "fr"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "France",
  },
  {
    id: "de",
    locales: ["en", "de"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Germany",
  },
  {
    id: "hu",
    locales: ["en", "hu"],
    oem_list: ["telekom"],
    label: "Hungary",
  },
  {
    id: "gr",
    locales: ["en", "el"],
    oem_list: ["telekom"],
    label: "Greece",
  },
  {
    id: "ro",
    locales: ["en", "ro"],
    oem_list: ["telekom"],
    label: "Romania",
  },
  {
    id: "sk",
    locales: ["en", "sk"],
    oem_list: ["telekom"],
    label: "Slovakia",
  },
  {
    id: "is",
    locales: ["en", "is"],
    oem_list: ["telekom"],
    label: "Iceland",
  },
  {
    id: "ie",
    locales: ["en"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Ireland",
  },
  {
    id: "it",
    locales: ["en", "it"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Italy",
  },
  {
    id: "lu",
    locales: ["en", "de", "fr"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Luxembourg",
  },
  {
    id: "nl",
    locales: ["en", "nl"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Netherlands",
  },
  {
    id: "no",
    locales: ["en", "nb"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Norway",
  },
  {
    id: "pl",
    locales: ["en", "pl"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Poland",
  },
  {
    id: "pt",
    locales: ["en", "pt"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Portugal",
  },
  {
    id: "es",
    locales: ["en", "es"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Spain",
  },
  {
    id: "se",
    locales: ["en", "sv"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Sweden",
  },
  {
    id: "ch",
    locales: ["en", "de", "fr", "it"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "Switzerland",
  },
  {
    id: "gb",
    locales: ["en"],
    oem_list: ["bmw", "telekom", "rrmc"],
    label: "United Kingdom",
  },
  {
    id: "ca",
    locales: ["en", "fr"],
    oem_list: ["bmwna", "daimlerna"],
    label: "Canada",
  },
  {
    id: "us",
    locales: ["en", "es"],
    oem_list: ["bmwna", "daimlerna"],
    label: "Unites States of America",
  },
  {
    id: "common",
    locales: ["en"],
    oem_list: ["hotspotdrive"],
    label: "Common",
  },
];

export const na_countries = ["us", "ca"];
export const na_oems = ["bmwna", "daimlerna"];

export const routes: IGCCPage[] = [
  { route: ERoutes.login, title: "Login" },
  { route: ERoutes.cidr, title: "CIDR" },
  { route: ERoutes.content, title: "Choose content to edit" },
  { route: ERoutes.subscriptions, title: "Edit subscriptions" },
  { route: ERoutes.passes, title: "Edit passes" },
  { route: ERoutes.intl, title: "Localization" },
  { route: ERoutes.image, title: "Images" },
  { route: ERoutes.html_editor, title: "Edit Content" },
  { route: ERoutes.products, title: "Product catalog" },
];

export const editable_pages = ["product"];
// export const routes = {
//   [ERoutes.login]: "Login",
//   [ERoutes.cidr]: "Login",
//   [ERoutes.content]: "Choose content to edit",
//   [ERoutes.html_editor]: "Login",
// };

export const file_upload_mime_types = {
  pdf: "application/pdf",
  html: "text/html",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export const namespaces_jumpboard: Array<INamespaceOption> = no_country_pages.map((page) => ({
  ...getDropdownOption(page),
  page,
  country: "",
}));

export const namespaces_oem: Array<INamespaceOption> = country_options.map(({ id, label }) => ({
  id,
  label,
  key: id,
  country: id,
  page: "common",
}));

export const na_namespaces_oem = namespaces_oem.filter(({ id }) => na_countries.includes(id));
export const emea_namespaces_oem = namespaces_oem.filter(({ id }) => !na_countries.includes(id));

export const telekom_brand_options: IDropdownOption[] = [
  { id: "volvo", label: "Volvo" },
  { id: "polestar", label: "Polestar" },
  { id: "saic", label: "SAIC" },
  { id: "byd", label: "BYD" },
];

export const country_currencies: ICurrency[] = [
  {
    country: "at",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "be",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "cz",
    isoCode: ECurrencyCode.czk,
    symbol: ECurrencySymbol.czk,
  },
  {
    country: "dk",
    isoCode: ECurrencyCode.dkk,
    symbol: ECurrencySymbol.dkk,
  },
  {
    country: "fi",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "fr",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "de",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "gr",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "hu",
    isoCode: ECurrencyCode.huf,
    symbol: ECurrencySymbol.huf,
  },
  {
    country: "is",
    isoCode: ECurrencyCode.isk,
    symbol: ECurrencySymbol.isk,
  },
  {
    country: "ie",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "it",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "lu",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "nl",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "no",
    isoCode: ECurrencyCode.nok,
    symbol: ECurrencySymbol.nok,
  },
  {
    country: "pl",
    isoCode: ECurrencyCode.pln,
    symbol: ECurrencySymbol.pln,
  },
  {
    country: "pt",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "ro",
    isoCode: ECurrencyCode.ron,
    symbol: ECurrencySymbol.ron,
  },
  {
    country: "sk",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "es",
    isoCode: ECurrencyCode.eur,
    symbol: ECurrencySymbol.eur,
  },
  {
    country: "se",
    isoCode: ECurrencyCode.sek,
    symbol: ECurrencySymbol.sek,
  },
  {
    country: "ch",
    isoCode: ECurrencyCode.chf,
    symbol: ECurrencySymbol.chf,
  },
  {
    country: "gb",
    isoCode: ECurrencyCode.gbp,
    symbol: ECurrencySymbol.gbp,
  },
  {
    country: "us",
    isoCode: ECurrencyCode.usd,
    symbol: ECurrencySymbol.usd,
  },
  {
    country: "ca",
    isoCode: ECurrencyCode.cad,
    symbol: ECurrencySymbol.cad,
  },
];

export const pass_type_options: IDropdownOption[] = [
  { id: "topUp", label: "Top-up" },
  { id: "worldPass", label: "World Pass" },
  { id: "freeWelcome", label: "Free Welcome" },
];
