import { setUrlParamsStr } from "utils";
import { EApiMethods, handleStageApi, handleStageApiv2 } from "./api_fetch";

interface IIntlReq {
  OEM: string;
  country: string;
  lang: string;
  page: string;
  searchKey?: string;
  updated_key?: { [key: string]: string };
}

export const save_intl_changes = async ({ OEM, country, lang, page, updated_key }: IIntlReq, environment?: string) => {
  const key = `${OEM}/${page}/${lang}${country ? `-${country.toUpperCase()}` : ""}.json`;
  const result = await handleStageApiv2(
    `/intl/locales`,
    EApiMethods.post,
    JSON.stringify({ key, updated_key }),
    environment
  );
  return result;
};

export const delete_intl_key = async ({ OEM, country, lang, page, updated_key }: IIntlReq) => {
  const key = `${OEM}/${page}/${lang}${country ? `-${country.toUpperCase()}` : ""}.json`;
  const result = await handleStageApi(`/intl/locales`, EApiMethods.delete, JSON.stringify({ key, updated_key }));
  return result;
};

export const get_intl_keys = async ({ OEM, country, lang, page, searchKey = "" }: IIntlReq) => {
  const paramsStr = setUrlParamsStr({
    key: encodeURIComponent(`${OEM}/${page}/${lang}${country ? `-${country.toUpperCase()}` : ""}.json`),
    searchKey,
  });
  const url = `/intl/locales${paramsStr}`;

  const result = await handleStageApi(url, EApiMethods.get);
  result.locale = lang;
  return result;
};

interface IIntlImportReq {
  OEMs: string[];
  namespaces: string[];
}
export const get_intl_url = async (key: string) => {
  const result = await handleStageApi(`/intl/url?key=${key}`, EApiMethods.get);
  return result;
};

export const import_intl_keys = async ({ OEMs, namespaces }: IIntlImportReq) => {
  const result = await handleStageApi(
    `/intl/import?OEMs=${OEMs.join(",")}&namespaces=${namespaces.join(",")}`,
    EApiMethods.post
  );

  if (result.status === 200) {
    const { importCount } = JSON.parse(result.response);
    return { importCount };
  }
  console.error(JSON.stringify(result));
  return {};
};
