import { isValid, parse } from "date-fns";
import { type IOptions, defaults } from "sanitize-html";
import { EValidationRules, type IForm, type IFormField, type IValidationRule, country_currencies } from ".";
import type { IDropdownOption } from "./interfaces";

const validate_form_value = (
  form: IForm,
  value: string | undefined,
  rule: EValidationRules,
  details?: string | number
) => {
  if (!value) return false; // sketchy required && !value

  if (rule === EValidationRules.exists) {
    return !!value;
  }
  if (rule === EValidationRules.length) {
    if (!details) details = 0;
    return value.length >= details;
  }
  if (rule === EValidationRules.regex) {
    if (!details) details = "";
    const reg_exp = new RegExp(details as string);
    return reg_exp.test(value);
  }
  if (rule === EValidationRules.matchField) {
    if (!details) return true;
    const otherField = form[details];
    if (!otherField) return false;
    return otherField.value === value;
  }
  if (rule === EValidationRules.datePattern) {
    const parsedDate = parse(value, "P", new Date());
    const isValidDate = isValid(parsedDate);
    return isValidDate;
  }
};

export const validate_form_field = (id: string, form: IForm) => {
  const { value, validation } = form[id];
  const new_validation: IValidationRule[] = [];
  validation.forEach((v: IValidationRule) => {
    const { validationRule, details } = v;
    const result = validate_form_value(form, value, validationRule, details);
    new_validation.push({ ...v, result });
  });
  const new_field = { ...form[id], validation: new_validation };
  return new_field;
};

export const validate_form = (form: IForm) => {
  const new_form: IFormField[] = [];
  Object.keys(form).forEach((f) => {
    const new_field = validate_form_field(f, form);
    new_form.push(new_field);
  });

  return new_form;
};

export const construct_form = (fields: IFormField[]) => {
  const form: IForm = {};
  fields.forEach((f) => {
    form[f.id] = f;
  });
  return form;
};

export const dropdown_sorter = (array: IDropdownOption[]) => {
  return array.sort((a, b) => (a.id > b.id ? 1 : -1));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const objectsEqual = (o1: any, o2: any): boolean =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const get_venn_difference = (set1: Array<object>, set2: Array<object>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const difference = <any>[];
  set1.forEach((o, idx) => {
    if (idx <= set2.length && !objectsEqual(o, set2[idx])) {
      difference.push(o);
    }
  });
  return difference;
};

export const get_sanitizer_conf = (image_base_url: string): IOptions => {
  const { allowedTags } = defaults;
  return {
    ...defaults,
    allowedTags: allowedTags.concat(["img", "link", "details", "summary"]),
    allowedAttributes: {
      "*": ["class"],
      a: ["href", "name", "target"],
      th: ["width"],
      td: ["width"],
      link: ["href", "rel"],
      img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
    },
    transformTags: {
      "*": (tagName: string, attribs: { [key: string]: string }) => {
        if (tagName.toLowerCase() === "div" && attribs.class === "info-content") return { tagName: "", attribs };
        if (!attribs.class) return { tagName, attribs };
        const el_classes = attribs.class
          .split(" ")
          .filter((el_class: string) => !el_class.includes("col-"))
          .map((el_class: string) => `info-content-${el_class.replace("info-content-", "")}`);
        return {
          tagName,
          attribs: {
            ...attribs,
            class: el_classes.join(" "),
          },
        };
      },
      img: (_tagName: string, attribs: { [key: string]: string }) => {
        const { src } = attribs;
        const image_name = src.indexOf("/") > -1 ? src.substring(src.lastIndexOf("/") + 1) : src;
        return {
          tagName: "img",
          attribs: {
            ...attribs,
            src: `${image_base_url}/${image_name}`,
            //src: `${content_url_base}/images/${OEM}/${country}/${image_name}`
          },
        };
      },
    },
  };
};

export const get_currency_symbol = (country: string): string => {
  let currencySymbol = "";
  const currency = country_currencies.find((currency) => currency.country === country);
  if (currency) currencySymbol = currency.symbol;
  return currencySymbol;
};

export const moveArrayItem = <T>(array: T[], to: number, from: number) => {
  const newArray = [...array];
  const item = newArray[from];
  newArray.splice(from, 1);
  newArray.splice(to, 0, item);
  return newArray;
};
