import "@telekom/scale-components/dist/scale-components/scale-components.css";
import { defineCustomElements } from "@telekom/scale-components/loader";
import { get_content_url, parse_html_file, post_content_changes } from "api/content";
import { get_intl_keys, save_intl_changes } from "api/intl";
import {
  EProductType,
  type IProduct,
  type IProductApi,
  getFilteredProducts,
  getProduct,
  saveProduct,
} from "api/products";
import {
  EApiResponseType,
  EUploadDocumentTypes,
  type ICommonJSON,
  type IDropdownOption,
  country_mapping,
  file_upload_mime_types,
  getEnvironmentType,
} from "components/shared";
import { moveArrayItem } from "components/shared/helpers";
import { type BaseSyntheticEvent, type ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const defaultDialogOptions = {
  open: false,
  text: "",
  dialogType: "",
};

interface IProductDialog {
  open: boolean;
  text: string;
  type?: "countryChange" | "productCatalog";
  property?: IDropdownOption;
}

interface IOrderedProduct extends IDropdownOption {
  order: number;
}

const defaultProduct = (productSK: string) => ({
  SK: "",
  country: "",
  type: EProductType.pass,
  contractId: "",
  subscriptionTypeId: 0,
  name: "",
  price: 0,
  currency: "",
  volumeValue: 0,
  conditions: [],
  volumeUnit: "",
  hideVolume: false,
  paymentCycleTextKey: { textKey: `product_${productSK}_period`, text: "" },
  freeTrialTextKey: { textKey: `product_${productSK}_free_trial_info`, text: "" },
});

const defaultConditionText = "New Product Information";

type TProps = {
  environment: string;
  productSK: string;
  OEM: string;
  type: EProductType;
};

const useProductCardEditor = ({ environment, productSK, OEM, type }: TProps) => {
  const navigate = useNavigate();
  const [product, setProduct] = useState<IProduct>(() => defaultProduct(productSK));
  const [intlKeys, setIntlKeys] = useState<ICommonJSON>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [language, setLanguage] = useState<IDropdownOption>({ id: "en", label: "en" });
  const [languageOptions, setLanguageOptions] = useState<IDropdownOption[]>([{ id: "en", label: "en" }]);
  const [relatedProductData, setRelatedProductData] = useState<IProduct[]>([]);
  const [initialRelatedProducts, setInitialRelatedProducts] = useState<IOrderedProduct[]>([]);
  const [reorderedRelatedProducts, setReorderedRelatedProducts] = useState<IOrderedProduct[]>([]);
  const [currentProductIndex, setCurrentProductIndex] = useState<number>(0);
  const [dialog, setDialog] = useState<IProductDialog>(defaultDialogOptions);
  const [orderDialogOpen, setOrderDialogOpen] = useState<boolean>(false);
  const [broadbandLabelHTML, setBroadbandLabelHTML] = useState<null | string>(null);

  const { REACT_APP_STATIC_CONTENT_CF_URL } = process.env;

  const loadData = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        const environmentType = getEnvironmentType(OEM, environment);
        const productResp = await getProduct(environmentType, OEM, productSK, type);
        const { id: lang } = language;
        const { country, conditions } = productResp;
        const { locales } = country_mapping.find(({ id }) => id === country) ?? { locales: ["en"] };

        const [intlKeys, intlKeysEn] = await Promise.all([
          get_intl_keys({
            OEM,
            country,
            lang,
            page: "products",
            searchKey: "",
          }),
          ...(lang !== "en"
            ? [
                get_intl_keys({
                  OEM,
                  country,
                  lang: "en",
                  page: "products",
                  searchKey: "",
                }),
              ]
            : [null]),
        ]);

        const { keys } = intlKeys;
        const { keys: keysEn = {} } = intlKeysEn || {};

        setIntlKeys(keys);

        const sortedConditions =
          conditions
            ?.sort(({ condition: keyA }, { condition: keyB }) => keyA.localeCompare(keyB))
            ?.map((sc, i) => ({
              ...sc,
              condition: `${sc.condition.substring(0, sc.condition.length - 1)}${i + 1}`,
              order: i + 1,
              text: keys[sc.condition] || keysEn[sc.condition] || "",
            })) || [];

        setProduct({
          ...productResp,
          conditions: sortedConditions,
          paymentCycleTextKey: {
            textKey: productResp?.paymentCycleTextKey || `product_${productSK}_period`,
            text: keys[productResp?.paymentCycleTextKey] || keysEn[productResp?.paymentCycleTextKey] || "month",
          },
          freeTrialTextKey: {
            textKey: productResp?.freeTrialTextKey || `product_${productSK}_free_trial_info`,
            text:
              keys[productResp?.freeTrialTextKey] ||
              keysEn[productResp?.freeTrialTextKey] ||
              "The offer ends automatically.",
          },
        });
        setLanguageOptions(locales.map((locale) => ({ id: locale, label: locale })));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [language, environment, OEM, productSK, type]);

  const handleReturnToCatalog = () => {
    if (!isChanged) {
      if (type === EProductType.subscription) {
        navigate(`/subscriptions/${OEM}`);
      } else if (type === EProductType.pass) {
        navigate(`/passes/${OEM}`);
      } else {
        navigate(`/subscriptions/${OEM}`);
      }
      return;
    }
    setDialog((prev) => ({
      ...prev,
      open: true,
      text: "There are some unsaved changes in the product card. If you navigate back to the Product Catalog any unsaved changes will be lost. Are you sure you want to navigate back to Product Catalog without saving the product card?",
      type: "productCatalog",
    }));
  };

  const handleNavigationDialogClose = () => {
    setDialog(defaultDialogOptions);
  };

  const handleNavigationDialogConfirm = () => {
    if (dialog.type === "productCatalog") {
      navigate(`/products/${OEM}`);
    } else if (dialog.type === "countryChange" && dialog?.property) {
      setLanguage(dialog.property);
      setDialog(defaultDialogOptions);
      setIsChanged(false);
    }
  };

  const handleChangeLanguage = (selectedLanguage: IDropdownOption) => {
    if (!isChanged) {
      setLanguage(selectedLanguage);
      return;
    }

    setDialog((prev) => ({
      ...prev,
      open: true,
      text: "There are some unsaved changes in the product card. If you change the language any unsaved changes will be lost. Are you sure you want to change the language without saving the product card?",
      type: "countryChange",
      property: selectedLanguage,
    }));
  };

  const handleAddConditionClick = () => {
    const productConditionCount = product?.conditions?.length ?? 0;

    if (productConditionCount === 5) return;

    setProduct((prev) => ({
      ...prev,
      conditions: [
        ...(prev?.conditions ? prev.conditions : []),
        {
          text: `${defaultConditionText} ${productConditionCount + 1}`,
          condition: `product_${product?.SK}_condition_${productConditionCount + 1}`,
          order: productConditionCount + 1,
          touched: false,
        },
      ],
    }));

    setIsChanged(true);
  };

  const handleRemoveConditionClick = (key: string) => {
    const conditions = product?.conditions
      ?.filter(({ condition }) => condition !== key)
      .map((c, i) => ({ ...c, condition: `product_${product?.SK}_condition_${i + 1}` }));
    setProduct((prev) => ({ ...prev, conditions }));
    setIsChanged(true);
  };

  const handleFreeTrialCheckChange = () => {
    setProduct((prev) => ({ ...prev, isFreeTrial: !prev?.isFreeTrial }));
    setIsChanged(true);
  };

  const handlePeriodTextChange = ({ target: { value: paymentCycleText } }: BaseSyntheticEvent) => {
    setProduct((prev) => ({
      ...prev,
      paymentCycleTextKey: {
        ...prev?.paymentCycleTextKey,
        text: paymentCycleText,
      },
    }));
    setIsChanged(true);
  };

  const handleFreeTrialTextChange = ({ target: { value: freeTrialText } }: BaseSyntheticEvent) => {
    setProduct((prev) => ({
      ...prev,
      freeTrialTextKey: {
        ...prev?.freeTrialTextKey,
        text: freeTrialText,
      },
    }));
    setIsChanged(true);
  };

  const handleConditionTextChange = (conditionText: string, index: number) => {
    const conditions = product?.conditions?.map((c, i) => {
      if (i === index) return { ...c, text: conditionText };
      return c;
    });
    setProduct((prev) => ({ ...prev, conditions }));
    setIsChanged(true);
  };

  const handlePassTypeChange = (passType: string) => {
    setProduct((prev) => ({ ...prev, passType }));
    setIsChanged(true);
  };

  const handleSettingProductOrder = (array: IOrderedProduct[]): IOrderedProduct[] =>
    array.map((item, index) => ({
      order: index + 1,
      id: item.id,
      label: item.label,
    }));

  const handleProductOrderChange = (id: number) => {
    const newIndex = id - 1;
    setCurrentProductIndex(newIndex);
    const productToMove = reorderedRelatedProducts.find(({ id }) => id === product.SK);

    if (productToMove) {
      const newProductOrder = moveArrayItem(reorderedRelatedProducts, newIndex, productToMove.order - 1);
      const reorderedProducts = handleSettingProductOrder(newProductOrder);
      setReorderedRelatedProducts(reorderedProducts);
    }
  };

  const handleOrderDialogOpen = async () => {
    if (!reorderedRelatedProducts.length) {
      setLoading(true);
      const environmentType = getEnvironmentType(OEM, environment);
      const filteredProducts = await getFilteredProducts(environmentType, OEM, product.country, type);
      const orderedRelatedProducts = filteredProducts
        .map(({ SK, name, order }, index) => ({
          order: order ?? index + 1,
          id: SK ?? "",
          label: name,
        }))
        .sort((a, b) => a.order - b.order);
      setRelatedProductData(filteredProducts);
      setInitialRelatedProducts(orderedRelatedProducts);
      setReorderedRelatedProducts(orderedRelatedProducts);
    }
    setLoading(false);
    setOrderDialogOpen(true);
  };

  const handleReorderCancel = () => {
    const originalProductIndex = initialRelatedProducts.findIndex((item: IOrderedProduct) => item.id === product.SK);
    setCurrentProductIndex(originalProductIndex + 1);
    setReorderedRelatedProducts(initialRelatedProducts);
    setOrderDialogOpen(false);
  };

  const handleReorderSave = () => {
    setInitialRelatedProducts(reorderedRelatedProducts);
    setIsChanged(true);
    setOrderDialogOpen(false);
  };

  const prepareUpdatedProduct = (product: IProduct, currentProductIndex: number): IProductApi => ({
    ...product,
    conditions: product?.conditions?.map(({ condition, order }) => ({ order, condition })),
    paymentCycleTextKey: product?.paymentCycleTextKey?.textKey,
    freeTrialTextKey: product?.freeTrialTextKey?.textKey,
    order: currentProductIndex,
  });

  const prepareUpdatedKeys = (product: IProduct, intlKeys: ICommonJSON, productSK: string): ICommonJSON => {
    const updatedKeys: ICommonJSON = {};

    for (const [key, value] of Object.entries(intlKeys)) {
      if (!key.includes(`product_${product?.SK}_condition_`)) {
        updatedKeys[key] = value;
      }
    }

    if (product?.conditions) {
      for (const { condition, text } of product.conditions) {
        updatedKeys[condition] = text;
      }
    }

    updatedKeys[`product_${productSK}_period`] = product?.paymentCycleTextKey?.text;
    updatedKeys[`product_${productSK}_free_trial_info`] = product?.freeTrialTextKey?.text;

    return updatedKeys;
  };

  const prepareSaveRelatedProducts = (
    relatedProductData: IProduct[],
    reorderedRelatedProducts: IOrderedProduct[],
    environmentType: string,
    OEM: string
  ): Promise<string>[] => {
    return relatedProductData
      .map((relatedProduct) => {
        if (relatedProduct.SK === product.SK) return null;

        const reorderedProductFound = reorderedRelatedProducts.find(
          (reorderedProduct) => reorderedProduct.id === relatedProduct.SK
        );

        if (reorderedProductFound?.order) {
          const updatedRelatedProduct: IProductApi = {
            ...relatedProduct,
            order: reorderedProductFound.order,
            paymentCycleTextKey: relatedProduct.paymentCycleTextKey?.textKey,
            freeTrialTextKey: relatedProduct.freeTrialTextKey?.textKey,
          };
          return saveProduct(environmentType, OEM, updatedRelatedProduct);
        }
        return null;
      })
      .filter((promise): promise is Promise<string> => promise !== null);
  };

  const saveAllChanges = async (
    updatedProduct: IProductApi,
    updatedKeys: ICommonJSON,
    saveRelatedProductsPromises: Promise<string>[]
  ) => {
    const { country } = product;
    const { id: lang } = language;

    if (environment === "prod") {
      await Promise.all([
        save_intl_changes(
          {
            OEM,
            country,
            lang,
            page: "products",
            updated_key: updatedKeys,
          },
          environment
        ),
        saveProduct(environment, OEM, updatedProduct),
        ...saveRelatedProductsPromises,
      ]);
    } else {
      await Promise.all([
        save_intl_changes(
          {
            OEM,
            country,
            lang,
            page: "products",
            updated_key: updatedKeys,
          },
          "review"
        ),
        save_intl_changes(
          {
            OEM,
            country,
            lang,
            page: "products",
            updated_key: updatedKeys,
          },
          "staging"
        ),
        saveProduct(environment, OEM, updatedProduct),
        ...saveRelatedProductsPromises,
      ]);
      await Promise.all([
        ...(environment === "prod"
          ? [save_intl_changes({ OEM, country, lang, page: "products", updated_key: updatedKeys }, "prod")]
          : [
              save_intl_changes({ OEM, country, lang, page: "products", updated_key: updatedKeys }, "staging"),
              save_intl_changes({ OEM, country, lang, page: "products", updated_key: updatedKeys }, "review"),
            ]),
        saveProduct(environment, OEM, updatedProduct),
        ...saveRelatedProductsPromises,
      ]);
    }
  };

  const handleSuccessfulSave = () => {
    setIsChanged(false);
    toast.success("Product card saved.");
  };

  const handleSaveError = (err: unknown) => {
    console.error(err);
    let errorMessage = "Error while saving product card.";
    if (err instanceof Error) {
      const { message } = JSON.parse(err.message);
      if (message) errorMessage = message;
    }
    toast.error(errorMessage);
  };

  const handleSaveChanges = async () => {
    if (!product || !isChanged) return;

    try {
      setLoading(true);

      const updatedProduct = prepareUpdatedProduct(product, currentProductIndex);
      const updatedKeys = prepareUpdatedKeys(product, intlKeys, productSK);
      const environmentType = getEnvironmentType(OEM, environment);
      const saveRelatedProductsPromises = prepareSaveRelatedProducts(
        relatedProductData,
        reorderedRelatedProducts,
        environmentType,
        OEM
      );

      await saveAllChanges(updatedProduct, updatedKeys, saveRelatedProductsPromises);

      handleSuccessfulSave();
    } catch (err) {
      handleSaveError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();

    // Loads Scale components, so they work correctly!
    defineCustomElements();
  }, [loadData]);

  useEffect(() => {
    const currentProductIndex = reorderedRelatedProducts.findIndex((item: IOrderedProduct) => item.id === product.SK);
    setCurrentProductIndex(currentProductIndex + 1);
  }, [reorderedRelatedProducts, product.SK]);

  const productCardEditorData = JSON.parse(sessionStorage.getItem("ProductCardEditorData") ?? "{}");
  const country = productCardEditorData.country;

  const formattedProductName = (product?.name || "noName")
    .toLowerCase()
    .replace(/\s+/g, "_")
    .replace(/[^a-z0-9_]/g, "");
  const broadbandLabelName = `${formattedProductName}_broadbandlabel`;
  const fileName = `${OEM}_${broadbandLabelName}`;

  const handleUploadBroadbandLabelHTML = async (file: Blob, fileType: keyof typeof EUploadDocumentTypes) => {
    try {
      if (!fileType) return;

      if (fileType === "html") {
        setLoading(true);

        if (!country) {
          throw new Error("Country not found in session storage");
        }

        // * Language could be hardcoded, because broadband label is always in English.
        const lang = "en";

        const fileNameWithExtension = `${OEM}_${country}_${lang}_${broadbandLabelName}.html`;
        const url_response = await get_content_url(
          `upload_${fileType}/${environment}/${fileNameWithExtension}`,
          file_upload_mime_types[fileType]
        );

        const { url } = url_response;
        await post_content_changes(url, file, {}, EApiResponseType.html);

        await parse_html_file(fileNameWithExtension, undefined, { OEM, country, lang, page_name: fileName });

        toast("Upload Successful!");
      } else {
        toast("File type is not HTML!");
      }
    } catch (ex) {
      toast("Error while uploading broadband label PDF!");
      console.error(ex);
    }
    setLoading(false);
  };

  const allowedFileType = EUploadDocumentTypes.html;

  const [uploadInProgress, setUploadInProgress] = useState(false);

  const handleUploadCall = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setUploadInProgress(true);
      const {
        target: { files, value },
      } = event;

      // tests weather the file has the right extension
      const pattern = new RegExp(`(${allowedFileType})$`, "i");

      if (pattern.test(value) && files?.length) {
        const fileExtension = value
          .replace(/^.*[\\/]/, "")
          .split(".")
          .pop();

        await handleUploadBroadbandLabelHTML(files[0], `${fileExtension}` as keyof typeof EUploadDocumentTypes);
        event.target.value = "";
        setUploadInProgress(false);
      } else {
        throw new Error(`Invalid file type, use file with extension ${allowedFileType}`);
      }
    } catch (ex) {
      event.target.value = "";
      setUploadInProgress(false);
      throw ex;
    }
  };

  const cfUrl = REACT_APP_STATIC_CONTENT_CF_URL ?? "";

  const htmlUrl = `${cfUrl}/${environment}/content/html/${OEM}/us/en/${fileName}.html`;

  const handleDownloadHTML = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    try {
      const response = await fetch(htmlUrl);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName ?? "download.html";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("HTML download failed:", error);
      toast.error(`HTML download failed: ${error}`);
    }
  };

  useEffect(() => {
    const fetchHTML = async () => {
      if (country === "us" && !broadbandLabelHTML) {
        try {
          const response = await fetch(htmlUrl);
          if (response.ok) {
            const htmlContent = await response.text();
            setBroadbandLabelHTML(htmlContent);
          } else {
            console.error("Failed to fetch HTML:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching HTML:", error);
        }
      }
    };

    fetchHTML();
  }, [country, broadbandLabelHTML, htmlUrl]);

  return {
    product,
    intlKeys,
    loading,
    isChanged,
    language,
    languageOptions,
    initialRelatedProducts,
    reorderedRelatedProducts,
    currentProductIndex,
    dialog,
    orderDialogOpen,
    allowedFileType,
    uploadInProgress,
    country,
    broadbandLabelHTML,
    handleReturnToCatalog,
    handleNavigationDialogClose,
    handleNavigationDialogConfirm,
    handleChangeLanguage,
    handleAddConditionClick,
    handleRemoveConditionClick,
    handleFreeTrialCheckChange,
    handlePeriodTextChange,
    handleFreeTrialTextChange,
    handleConditionTextChange,
    handlePassTypeChange,
    handleProductOrderChange,
    handleOrderDialogOpen,
    handleReorderCancel,
    handleReorderSave,
    handleSaveChanges,
    setOrderDialogOpen,
    handleUploadCall,
    handleDownloadHTML,
  };
};

export default useProductCardEditor;
