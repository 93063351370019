export enum EValidationRules {
  "exists",
  "length",
  "regex",
  "datePattern",
  "matchField",
}

export enum EFormFieldTypes {
  "select",
  "text",
  "email",
  "password",
  "datetime",
}

export enum EOEMValues {
  hotspotdrive = "hotspotdrive",
  bmw = "bmw",
}

export enum ERoutes {
  content = "",
  cidr = "cidr",
  html_editor = "html-editor",
  login = "login",
  intl = "intl",
  image = "image",
  products = "products",
  subscriptions = "subscriptions",
  passes = "passes",
}

export enum EApiResponseType {
  json = "json",
  html = "html",
  pdf = "pdf",
  xlsx = "vnd.ms-excel",
  docx = "vnd.openxmlformats-officedocument.wordprocessingml.document",
  image = "image",
}

export enum EUploadDocumentTypes {
  pdf = ".pdf",
  html = ".html",
  docx = ".docx",
  image = ".jpeg,.jpg,.png,.webp",
}

export enum ECurrencyCode {
  eur = "EUR",
  czk = "CZK",
  dkk = "DKK",
  huf = "HUF",
  isk = "ISK",
  nok = "NOK",
  pln = "PLN",
  ron = "RON",
  sek = "SEK",
  chf = "CHF",
  gbp = "GBP",
  usd = "USD",
  cad = "CAD",
}

export enum ECurrencySymbol {
  eur = "€",
  czk = "Kč",
  dkk = "kr.",
  huf = "Ft",
  isk = "kr",
  nok = "kr",
  pln = "zł",
  ron = "lei",
  sek = "kr",
  chf = "Fr.",
  gbp = "£",
  usd = "$",
  cad = "$",
}

export enum EDataTableActionIconTypes {
  download = "download",
  delete = "delete",
  upload = "upload",
  edit = "edit",
  pdf = "pdf",
  html = "html",
  docx = "docx",
  save = "save",
  cancel = "cancel",
  editDocument = "editDocument",
}

export enum EDataTableActionTypes {
  download = "download",
  delete = "delete",
  upload = "upload",
  edit = "edit",
  pdf = "pdf",
  html = "html",
  docx = "docx",
  editDocument = "editDocument",
}

export enum EOEMApps {
  bmw = "bmw",
  bmwna = "bmwna",
  daimlerna = "daimlerna",
  volvo_polestar = "telekom",
  rrmc = "rrmc",
}

export enum EEnvs {
  review = "review",
  staging = "staging",
  prod = "prod",
}
