import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Card, CardContent, type SxProps, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import "@telekom/scale-components/dist/scale-components/scale-components.css";
import { Download, Upload } from "@mui/icons-material";
import styled from "@emotion/styled";
import type { EProductType } from "api/products";
import { Dropdown } from "components/atoms/dropdown";
import { SimpleCheckbox } from "components/atoms/simple-checkbox";
import ProductCard from "components/molecules/product-card";
import { type IDropdownOption, type environmentTypes, pass_type_options } from "components/shared";
import type { BaseSyntheticEvent, FC } from "react";
import useProductCardEditor from "./useProductCardEditor";

const Input = styled.input`
  display: none;
`;

interface IProductCardEditorProps {
  environment: environmentTypes;
  productSK: string;
  OEM: string;
  type: EProductType;
  wrapperCss?: SxProps;
}

const ProductCardEditor: FC<IProductCardEditorProps> = ({ wrapperCss, environment, productSK, OEM, type }) => {
  const {
    product,
    intlKeys,
    loading,
    isChanged,
    language,
    languageOptions,
    initialRelatedProducts,
    reorderedRelatedProducts,
    currentProductIndex,
    dialog,
    orderDialogOpen,
    allowedFileType,
    uploadInProgress,
    country,
    broadbandLabelHTML,
    handleReturnToCatalog,
    handleNavigationDialogClose,
    handleNavigationDialogConfirm,
    handleChangeLanguage,
    handleAddConditionClick,
    handleRemoveConditionClick,
    handleFreeTrialCheckChange,
    handlePeriodTextChange,
    handleFreeTrialTextChange,
    handleConditionTextChange,
    handlePassTypeChange,
    handleProductOrderChange,
    handleOrderDialogOpen,
    handleReorderCancel,
    handleReorderSave,
    handleSaveChanges,
    setOrderDialogOpen,
    handleUploadCall,
    handleDownloadHTML,
  } = useProductCardEditor({ environment, productSK, OEM, type });

  return (
    <Box sx={wrapperCss}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={dialog?.open}
        onClose={handleNavigationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Unsaved changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialog?.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNavigationDialogClose}>Cancel</Button>
          <Button onClick={handleNavigationDialogConfirm} variant="contained" autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container direction="row" paddingBottom={1}>
        <Button onClick={handleReturnToCatalog} variant="contained">
          To {type} Catalog
        </Button>
        <Button onClick={handleSaveChanges} variant="contained" sx={{ marginLeft: "10px" }} disabled={!isChanged}>
          Save Changes
        </Button>
        <Dropdown
          id="select-language"
          placeholder="Select language"
          options={languageOptions}
          selectedValue={language}
          handleChange={(selected: IDropdownOption | undefined) =>
            handleChangeLanguage(selected ?? { id: "en", label: "en" })
          }
          sx={{ minWidth: "180px", marginLeft: "10px" }}
        />
        <Button onClick={handleOrderDialogOpen} variant="outlined" sx={{ marginLeft: "10px" }}>
          Reorder products
        </Button>
        <Dialog open={orderDialogOpen} onClose={() => setOrderDialogOpen(false)}>
          <DialogTitle>Reorder Products</DialogTitle>
          <DialogContent>
            <Grid spacing={2}>
              <span>Change product position: </span>
              <Dropdown
                id="product_order"
                placeholder="Select order"
                options={initialRelatedProducts.map((_, index) => ({ id: `${index + 1}`, label: `${index + 1}` }))}
                selectedValue={{ id: `${currentProductIndex}`, label: `${currentProductIndex}` }}
                handleChange={(selected: IDropdownOption | undefined) => {
                  handleProductOrderChange(Number.parseInt(selected?.id ?? "0"));
                }}
                sx={{ minWidth: "80px", marginLeft: "10px", marginBottom: "10px" }}
              />
              {reorderedRelatedProducts.map(({ id, label }) => (
                <Card
                  variant="outlined"
                  key={id}
                  sx={{ marginTop: "10px" }}
                  style={label === product.name ? { borderColor: "#e20074", borderWidth: "2px" } : undefined}
                >
                  <CardContent sx={{ padding: "12px 16px 14px", "&:last-child": { paddingBottom: "14px" } }}>
                    {label}
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReorderCancel}>Cancel</Button>
            <Button onClick={handleReorderSave} variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      {/* Card content editor */}
      {product && (
        <Grid container spacing={2}>
          {/* Additional item list */}
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {product.type === "pass" ? (
                <Grid item xs={12} sx={{ marginTop: "16px" }}>
                  <Dropdown
                    id="select-pass-type"
                    placeholder="Select pass type"
                    options={pass_type_options}
                    selectedValue={pass_type_options.find(({ id }) => id === product.passType)}
                    handleChange={(pass: IDropdownOption | undefined) => {
                      const passType = pass?.id ?? pass_type_options[0]?.id;
                      handlePassTypeChange(passType);
                    }}
                    sx={{ background: "none", minWidth: "180px", textAlign: "left" }}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <SimpleCheckbox
                      selected={product.isFreeTrial || false}
                      handleChange={handleFreeTrialCheckChange}
                      label="Is Free Trial"
                    />
                  </Grid>
                  {product.isFreeTrial && (
                    <Grid item xs={12}>
                      <TextField
                        placeholder={product?.freeTrialTextKey?.textKey}
                        value={product?.freeTrialTextKey?.text}
                        onChange={handleFreeTrialTextChange}
                        sx={{ minWidth: "450px", width: "80%" }}
                        label="Free trial text"
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      placeholder={product?.paymentCycleTextKey?.textKey}
                      value={product?.paymentCycleTextKey?.text}
                      onChange={handlePeriodTextChange}
                      label="Period text"
                      sx={{ minWidth: "450px", width: "80%" }}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                </>
              )}
              {product?.conditions?.length !== 5 && (
                <Grid item xs={12}>
                  Add new list item
                  <IconButton onClick={handleAddConditionClick}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              )}
              {product?.conditions?.map(({ condition, text }, index) => (
                <Grid item xs={12} key={condition}>
                  <TextField
                    value={text}
                    onChange={({ target: { value: conditionText } }: BaseSyntheticEvent) =>
                      handleConditionTextChange(conditionText, index)
                    }
                    placeholder={condition}
                    sx={{ minWidth: "450px", width: "80%" }}
                    inputProps={{ maxLength: 100 }}
                  />
                  <IconButton onClick={() => handleRemoveConditionClick(condition)}>
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              ))}

              {/* Broadband Nutrition Label */}
              {country === "us" && (
                <Grid item xs={12}>
                  <b>Broadband Nutrition Label HTML</b>
                  <Grid item xs={12} sx={{ marginTop: "16px" }}>
                    <label htmlFor="upload-broadband-label">
                      <Input
                        accept={allowedFileType}
                        id="upload-broadband-label"
                        name="upload-broadband-label"
                        type="file"
                        onChange={(event) => handleUploadCall(event)}
                      />
                      <Button aria-label="upload html" variant="contained" component="span" disabled={uploadInProgress}>
                        {uploadInProgress ? (
                          <CircularProgress size={24} sx={{ color: "white", marginRight: "10px" }} />
                        ) : (
                          <Upload />
                        )}
                        Upload
                      </Button>
                    </label>

                    <Button
                      variant="contained"
                      aria-label="download html"
                      sx={{ marginLeft: "10px" }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleDownloadHTML(event)}
                      disabled={uploadInProgress}
                    >
                      <Download />
                      Download
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Card preview */}
          <Grid item xs={4}>
            {product && intlKeys && (
              <ProductCard
                product={product}
                conditions={product?.conditions || []}
                period={product?.paymentCycleTextKey.text}
                isFreeTrial={product.isFreeTrial || false}
                freeTrialText={product?.freeTrialTextKey.text}
                passType={product.passType}
                broadbandLabelHTML={broadbandLabelHTML}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ProductCardEditor;
